import React, { Component } from 'react'
import ComponentBanner from '../componentBanner/ComponentBanner'
import ProcessItem from './ProcessItem'
import processData from "../../data/processData";

import './process.css'

export default class Process extends Component {
    render() {

        // const projectComponents = projectData.map (project => <ProjectItem key={project.id} img={project.imgUrl} title={project.title} tech={project.technology} />)

        const processComponents = processData.map( process => <ProcessItem key={process.id} id={process.id} name={process.processName} icon1={process.stepIcon1} title1={process.stepTitle1} description1={process.stepDescription1} icon2={process.stepIcon2} title2={process.stepTitle2} description2={process.stepDescription2} icon3={process.stepIcon3} title3={process.stepTitle3} description3={process.stepDescription3}/>

        )
        return (
            <div>
                <ComponentBanner componentTitle="My Process" description="From wireframes to code, below is a detailed look at how I run a typical project." />
                {processComponents}
            </div>
        )
    }
}
