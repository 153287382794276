import { BrowserRouter as Router, Route } from "react-router-dom";

import Header from './header/Header';
import LandingPage from './landingPage/LandingPage';
import Projects from './projects/Projects';
import Process from './process/Process';
import MyServices from './myServices/MyServices';
import Contact from './contact/Contact';
import About from './aboutPage/About';
import Footer from './footer/Footer';

import './App.css';

function App() {
  return (
    <Router>
    <Header />
    <Route path="/" exact> <LandingPage /></Route> 
    <Route path="/projects"><Projects /></Route>
    <Route path="/process"><Process /></Route> 
    <Route path="/services"><MyServices /></Route>
    <Route path="/contact"><Contact /></Route>
    <Route path="/about"><About /></Route>
    <Footer />
    </Router> 
  );
}

export default App;
