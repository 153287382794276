import React, { Component } from 'react'

import './myService.css'

export default class MyService extends Component {
    render() {
        return (
            <div className="col-sm-12 col-md-6 service justify-content-center text-center p-5 service-inner">
                <div className="text-center d-flex justify-content-center"><p className="service-icon">{this.props.icon}</p></div>
                <h1>{this.props.heading}</h1>
                <h4>{this.props.subHeading}</h4>
                <p>{this.props.description}</p>
            </div>
        )
    }
}
