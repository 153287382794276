import React, { Component } from 'react'
import serviceData from "../../data/serviceData";
import ComponentBanner from '../componentBanner/ComponentBanner';
import './myServices.css'

export default class MyServices extends Component {
    render() {
            const serviceComponents = serviceData.map( service => {
                return(
                    <div className="container service-pg-wrap">
                    <div className="row py-5">
                        <div className="col-sm-12 col-md-4">
                            <div className="service-pg-icon">{service.icon}</div>
                        </div>
                        <div className="col-sm-12 col-md-8">
                        <div className="service-pg-content">
                            <div className="serviceName">{service.serviceName} <br /></div>
                            <div className="heading">{service.heading1}<br /></div>
                            <div className="description">{service.description1} <br /></div>
                            <div className="heading">{service.heading2}<br /></div>
                            <div className="description">{service.description2} <br /></div>
                            <div className="heading">{service.heading3}<br /></div>
                            <div className="description">{service.description3} <br /></div>
                            </div>
                        </div>
                    </div>
                    </div>
                )
            })
        return (
            <div>
                <ComponentBanner componentTitle="My Services" description="Everything I offer with a detailed overview of each." />
                {serviceComponents}
            </div>
        )
    }
}
