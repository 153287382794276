import React, { Component } from 'react'
import AboutItem from './about-item/AboutItem'
import {   FaGrinWink} from "react-icons/fa";


import './about.css'
import ComponentBanner from '../componentBanner/ComponentBanner';

export default class About extends Component {

    render() {
    const images = [
                        {
                            "key": 1,
                            "imgUrl": "images/bbb.png",
                        },
                        {
                            "key": 2,
                            "imgUrl": "images/3.png",
                        },
                        {
                            "key": 3,
                            "imgUrl" : "images/5.png",
                        },
                        {
                            "key": 4,
                            "imgUrl" : "images/1.png",
                        },
                        
                    ]
        return (
            <div className="">
                <ComponentBanner componentTitle="About Me" description="Find out who I am, where I'm from and what I'm all about." />

                <div className=" container ">
                    <div className="row py-5">
                        <div className="col-8">
                        <h3 className="text">Who I Am</h3>
                        <p>
                        <div className="intro-text" >My name's Deepika. I'm a web designer and developer based in Vancouver, Canada.</div><br></br>
                        
                        <div className="about-text">Currently I'm a student at British Columbia Institute of Technology. I enjoy turning complex problems into simple, beautiful and intuitive designs. During the day I’m working on the school projects and assignments, sometimes at night as well <span><FaGrinWink className="availability" /></span>. When I'm not coding, tweeting or pushing pixels, you'll find me cooking, gardening or working out in the park.</div><br></br>
                        <div className="about-text">
                        My most recent project is called <span className="availability"> Armoire </span>. It’s a simple yet cool application that lets the user decide the outfit for each day thus cutting down the decide time.
                        </div>
                        </p><br></br>
                    {/* <h4><b><em>“ Code is like humor. When you have to explain it, it’s bad.” – Cory House</em></b></h4> */}
                    {/* <div className="row justify-content-center">   
                        {images.map(image => <AboutItem key={image.id} img={image.imgUrl}  />)}
                    </div> */}
                        </div>
                        <div className="col-4">
                        <img src="images/main.jpg" alt="Deepika" className="img-fluid" width="250"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <h3 className="text">What I do</h3>
                        </div>
                    </div>
                        <br></br> <br></br>
                    <div className="row">
                        <div className="col-12">
                           
                        <h5>When I code</h5>
                        <br></br>
                        <div className="row portfolio-images">
                            <div className="col-3">
                            <img src="images/5.jpg" alt="Deepika" className="img-fluid"/>
                            </div>
                            <div className="col-3">
                            <img src="images/4.jpg" alt="Deepika" className="img-fluid"/>
                            </div>
                            <div className="col-3">
                            <img src="images/6.jpg" alt="Deepika" className="img-fluid"/>
                            </div>
                            <div className="col-3 don-img">
                            <img src="images/google.png" alt="Deepika" className="img-fluid"/>
                            </div>
                        </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div className="col-12 mt-5">
                        <h5>When I don't</h5>
                        <div className="row">
                        {images.map(image => <AboutItem key={image.id} img={image.imgUrl}  />)}
                        </div>
                        </div>
                    </div>
                    
                    <div className="row pt-5">
                    <div className="col-4">
                        <img src="images/main2.png" alt="Deepika" className="img-fluid" width="250"/>
                        </div>
                        <div className="col-8">
                        <h3 className="text">Random Facts</h3>
                        <p>
                        <div className="intro-text" >I drink a lot of Tea</div><br></br>
                        <div className="intro-text" >I love to cook(eat as well)</div><br></br>
                        <div className="intro-text" >I love to travel</div><br></br>
                        <div className="intro-text" >I love to Dance</div><br></br>
                        <div className="intro-text" >Strawberry Shortcake is life</div><br></br>
                        <div className="intro-text" >I'm addicted to Instagram</div><br></br>
                        
                        
                        </p>
                        
                        <div className="intro-text" ><b><em> For me Code is like humor. When you have to explain it, it’s bad. <FaGrinWink className="availability" /></em></b></div><br></br>
                   
                    {/* <h4><b><em>“ Code is like humor. When you have to explain it, it’s bad.” – Cory House</em></b></h4> */}
                    {/* <div className="row justify-content-center">   
                        {images.map(image => <AboutItem key={image.id} img={image.imgUrl}  />)}
                    </div> */}
                        </div>
                        
                    </div>
                    
                </div>
            </div>
            
        )
    }
}
