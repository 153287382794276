import React, { Component } from 'react'
import './aboutItem.css'


export default class AboutItem extends Component {
    render() {
        // const img =
        return (
            <div className="col-sm-3 col-lg-3 don-img">
                
                <img src= {this.props.img} alt= {this.props.img} className="img-fluid"></img>

            </div>
        )
    }
}
