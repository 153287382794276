import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './header.css'

export default class Header extends Component {
    render() {
        return (
            <header className="main-nav ">
                
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark main-nav ">
                    <NavLink to="/" className="navbar-brand m-1" href="index.html"><img className="logo" src="/images/logo.png" alt="logo"/></NavLink>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav ml-auto nav-links ">
                        <li className="nav-item active px-4">
                            <NavLink to="/projects" className="nav-link" href="index.html">Projects  <span className="sr-only">(current)</span></NavLink>
                        </li>
                        <li className="nav-item px-4">
                            <NavLink to="/services" className="nav-link" href="index.html">Services</NavLink>
                        </li>
                        <li className="nav-item px-4">
                            <NavLink to="/process" className="nav-link" href="index.html">Process</NavLink>
                        </li>
                        <li className="nav-item px-4">
                            <NavLink to="/about" className="nav-link" href="index.html">About</NavLink>
                        </li>
                        <li className="nav-item px-4">
                            <NavLink to="/contact" className="nav-link" href="index.html">Contact</NavLink>
                        </li>
                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}
