import React, { Component } from 'react'
import MyService from './MyService'
import { FaPaintBrush,FaDesktop } from "react-icons/fa";

import './services.css'
import { Link } from 'react-router-dom';

export default class Services extends Component {
    render() {
        return (
            <div className="container-fluid">
                <h5 className="featured">MY SERVICES</h5>
                <div className="text-center justify-content-center d-flex "><div className="head-border m-0"></div></div>
                <div className="row mb-5 text-center">
                {/* icon={<FaPaintBrush />} */}
                    <MyService icon={<FaPaintBrush />} heading={"Web Design"} subHeading={"User Focused"} description={"A website should be designed for the people who will use it, so that's exactly what I do. User focused design should be the primary goal of any website."} />
                    <MyService icon={<FaDesktop />} heading={"Web Development"} subHeading={"Responsive and Fast"} description={"Every website should be built with two primary goals: Firstly, it needs to work across all devices. Secondly, it needs to be fast as possible."} />
                    <div className="col-12 text-center"><Link to="/services" className="btn-outline-secondary rounded-pill project-button project-button-test service-button py-3 px-4">View Details </Link></div>
                </div>
                 
                    
            </div>
        )
    }
}
