const projectData = [
    {
        imgUrl: "/images/armoire.png",
        title: "Armoire",
        description: "A simple yet cool application that lets the user decide the outfit for each day thus cutting down the decide time.",
        contribution1: "ERD Design",
        contribution2: "Web Design",
        contribution3: ".Net Development"
    },
    {
        imgUrl: "/images/featured-project.png",
        title: "MovieGush",
        description: "A simple react app that takes data from an api and displays the popular, upcoming and top-rated movies.",
        contribution1: "Logo Design",
        contribution2: "Web Design",
        contribution3: "React App Development"
    },
    {
        imgUrl: "/images/angular.png",
        title: "Vancouver Souvenirs",
        description: "An e-commerce app developed in Angular to sell souvenirs to the visitors of Vancouver.",
        contribution1: "Design Concept",
        contribution2: "Web Design",
        contribution3: "Static site Development"
    },
    
]

export default projectData