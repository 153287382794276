import { FaPenNib, FaDesktop } from "react-icons/fa";

const serviceData = [
    {
        id: 1,
        icon: <FaPenNib />,
        serviceName: "Web Design",
        heading1: "User Focused",
        description1: "A website should be designed for the people who will use it, so that's exactly what I do. User focused design should be the primary goal of any website.",
        heading2: "eCommerce",
        description2: "Over the past year I've learned the best ways to design an Internet page that uses different kinds of software to create a rich, interactive experience for the user thereby facilitating the user to be actively engaged with the site and provide real value to customers.",
        heading3: "Application Utility",
        description3: "The user interface of an application, whether it's mobile or desktop, can make or break a business. Getting the interface right and most importantly of all, making it easy to navigate, are the keys to a successful app."
    },
    {
        id: 2,
        icon: <FaDesktop />,
        serviceName: "Web Development",
        heading1: "HTML / CSS",
        description1: "Creating a strong foundation for a website means getting the HTML and CSS architecture right. If you've got a design I can create the front-end code for it.",
        heading2: "React / Angular",
        description2: "Almost all of the websites I make are done using React / Angular and the reason is simple: It just works. You can easily update every piece of content, turn sections on or off and so much more.",
        heading3: "Performance",
        description3: "Having a fast website is key to keeping people around which ultimately converts them into customers. There are a myriad of techniques involved in making a fast website, all of which I've spent years learning. I build these techniques into every site I code and can also offer this as a standalone service for existing websites."
    }
]


export default serviceData