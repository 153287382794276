import React, { Component } from 'react'
import ComponentBanner from "../componentBanner/ComponentBanner";

import './contact.css';

export default class Contact extends Component {
    render() {
        return (
            <div >
                <ComponentBanner componentTitle="Contact Me" description="If you’d like to chat about a project please fill in the form below and I’ll get back within 1-2 days." />

                <div className="container text-center">
                    <div class="contact-background">
                    <h3 className="availability"><b>Get in touch!</b></h3>
                    <p className="pt-2">I am interested in freelance opportunities – especially ambitious or large projects. However, if you have other request or question, don’t hesitate to contact me using below form either.</p>
                    </div>
                    <div className="row form">
                        <div className="col-sm-12 col-md-6 form-group form-inners">
                            <label htmlFor="name" >Name</label>
                            <input class="form-control" type="text" name="name" id="name"  />
                        </div>

                        <div className="col-sm-12 col-md-6 form-group form-inners">
                            <label htmlFor="email" >Email</label>
                            <input class="form-control" type="text" name="email" id="email"  />
                        </div>
                        <div class="form-group col-sm-12 form-inners">
                            <label for="comment">How can I help!</label>
                            <textarea class="form-control" rows="10" id="comment"></textarea>
                        </div>
                        <div className="col-12 mt-3 d-flex p-0 ">
                            <div className="form-button">
                                <button className="btn btn-outline-secondary contact-btn rounded-pill project-button project-button-test service-button py-3 px-4">Send Message </button>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        )
    }
}
