import React, { Component } from 'react'
// import ProjectItem from './project-item/ProjectItem'
import ComponentBanner from '../componentBanner/ComponentBanner';
import projectData from "../../data/projectData";
import ProjectImage from './project-item/ProjectImage';
import ProjectDesc from './project-item/ProjectDesc';

import './projects.css'

export default class Projects extends Component {
    render() {
        return (
           <div>
                <ComponentBanner componentTitle="My Projects" description="For the past year I’ve been designing and developing websites. Below are some of my favourites."/>

                <div className="container-fluid"> 
                    <div className="d-flex row project py-5 ">
                        <ProjectImage img= {projectData[0].imgUrl}/>
                        <ProjectDesc title={projectData[0].title}  description={projectData[0].description} contribution1={projectData[0].contribution1} contribution2={projectData[0].contribution2} contribution3={projectData[0].contribution3} />
                    </div> 
                    <div className="d-flex row project">
                    <ProjectDesc title={projectData[1].title}  description={projectData[1].description} contribution1={projectData[1].contribution1} contribution2={projectData[1].contribution2} contribution3={projectData[1].contribution3} />
                        <ProjectImage img= {projectData[1].imgUrl} />
                    </div> 
                    <div className="d-flex row project">
                        <ProjectImage img= {projectData[2].imgUrl}/>
                        <ProjectDesc title={projectData[2].title}  description={projectData[2].description} contribution1={projectData[2].contribution1} contribution2={projectData[2].contribution2} contribution3={projectData[2].contribution3} />
                    </div> 
                </div>
           </div>
        )
    }
}
