import React, { Component } from 'react'
import { FaTwitter, FaInstagram, FaGithub, FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import './footer.css'

export default class Footer extends Component {
    render() {
        return (
           <footer>
               <div class="container">
                   <div className="row">
                   <div class="col-sm-12 col-md-4">
                        <div>
                            <h2>Let's Work Together</h2>
                            <p>Have a project you would like to discuss?</p>
                        </div>
                        <div className="col-12 mt-3 d-flex p-0"><Link to="/contact" className="btn-outline-secondary rounded-pill project-button project-button-test service-button py-3 px-4">Get in Touch</Link></div>         
                   </div>
                   <div className="col-sm-12 col-md-4">
                    <div className="">
                    <ul className="navbar-nav ml-auto nav-links p-0">
                        <li className="nav-item active px-4 ">
                            <NavLink to="/projects" className="nav-link" href="index.html">Projects  <span className="sr-only">(current)</span></NavLink>
                        </li>
                        <li className="nav-item px-4">
                            <NavLink to="/services" className="nav-link" href="index.html">Services</NavLink>
                        </li>
                        <li className="nav-item px-4">
                            <NavLink to="/process" className="nav-link" href="index.html">Process</NavLink>
                        </li>
                        <li className="nav-item px-4">
                            <NavLink to="/about" className="nav-link" href="index.html">About</NavLink>
                        </li>
                        <li className="nav-item px-4">
                            <NavLink to="/contact" className="nav-link" href="index.html">Contact</NavLink>
                        </li>
                        </ul>
                    </div>
                   </div>
                   <div className="col-sm-12 col-md-4">
                    <div className="footer-list">
                        <ul>
                            <a href="https://www.linkedin.com/in/deepikaverma87/"><li><FaLinkedin />  ON LINKEDIN</li></a>
                            <a href="https://twitter.com/Deepika00831794"><li><FaTwitter />  ON TWITTER</li></a>
                            <a href="https://github.com/deepikaverma710"><li><FaGithub />  ON GITHUB</li></a>
                           <a href="https://www.instagram.com/d.e.e.p.i.k.a.v.e.r.m.a/"> <li><FaInstagram />  ON INSTAGRAM</li></a>
                        </ul>
                    </div>
                   </div>
                   </div>
               </div>
               <div className="text-center p-5 copyright">
                    &copy; 2020 Deepika Verma. All rights reserved.
               </div>
           </footer>
        )
    }
}
