import React, { Component } from 'react'

export default class ProcessItem extends Component {
    render() {
        return (
            <div className="container py-5">
                <h3><b>STEP {this.props.id} - {this.props.name} </b></h3>
                <hr />
                
                    <div className="row my-2 mt-4">
                        <div className="col-sm-12 col-md-2 text-center" >
                            <div className="icon-bg"> <p>{this.props.icon1}</p></div>
                        </div>
                        <div className="col-sm-12 col-md-10 process-item-text" >
                            <h4><b>{this.props.title1}</b></h4> 
                            <p> {this.props.description1}</p>
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col-sm-12 col-md-2 text-center" >
                            <div className="icon-bg"> <p>{this.props.icon2}</p></div>
                        </div>
                        <div className="col-sm-12 col-md-10 process-item-text" >
                            <h4><b>{this.props.title2}</b></h4> 
                            <p> {this.props.description2}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-2 text-center" >
                            <div className="icon-bg"> <p>{this.props.icon3}</p></div>
                        </div>
                        <div className="col-sm-12 col-md-10 process-item-text" >
                            <h4><b>{this.props.title3}</b></h4> 
                            <p> {this.props.description3}</p>
                        </div>
                    </div>
                
            </div>
        )
    }
}
