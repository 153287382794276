import React, { Component } from 'react'
import { FaArrowRight, FaCode, FaPenNib } from 'react-icons/fa'
import Services from '../services/Services'
import Testimonial from './Testimonial'
// import banner from '.../images/banner1.jpg'

import './landingPage.css'
import { Link } from 'react-router-dom'


export default class LandingPage extends Component {
    render() {
        return (
           <div>

               {/* ------------------- Banner Section -----------------------------  */}
               <div className="banner justify-content-center align-items-center d-flex " >
                   <div className="container">
                   <div className="row justify-content-center align-items-center">
                    <div className="banner-image col-sm-12 col-md-6 text-right">
                        <img src="images/potrait.png" alt="Deepika" className=" pot-img" width="500" />
                    </div>
                    <div className='display-4 col-sm-12 col-md-6 banner-main-txt'>
                        Hello, I'm <span className="banner-text-name">Deepika</span>.<br />
                        I'm a full-stack web developer.<br />
                        <p className="banner-subtext">I enjoy turning complex problems into simple, beautiful and intuitive designs. I create bespoke websites to help people go further online.</p>
                        <Link to="/projects" className="btn-outline-secondary rounded-pill  banner-button py-3 px-4">My Projects  <FaArrowRight /></Link>         
                        <br />
                    </div>  
                    </div>
                    </div>
                </div>

               {/* ------------------- Featured project Section -----------------------------  */}

                <h5 className="featured">FEATURED PROJECT</h5>
                <div className="text-center justify-content-center d-flex "><div className="head-border"></div></div>
                <div className="d-flex row project">
                <div className='project-text col-sm-12 col-md-6'>
                        <h2 className="project-title">Armoire</h2>
                        <p>A simple yet cool application that lets the user decide the outfit for each day thus cutting down the decide time.</p>
                        <ul className="project-features">
                            <li><FaPenNib /> &nbsp;Web Design</li>
                            <li><FaCode /> &nbsp;Development</li>
                        </ul>
                            
                        <div className="col-12 p-0 text-left"><a href="https://armoire.azurewebsites.net/" className="btn-outline-secondary rounded-pill project-button project-button-test service-button py-3 px-4">View Details </a></div>
                    </div> 
                    <div className="banner-image col-sm-12 col-md-6">
                        <img src="images/armoire.png" alt="Deepika" className="img-fluid" />
                    </div> 
                </div>

               {/* ------------------- Testimonial Section -----------------------------  */}

               <Testimonial testimonial="Deepika is an excellent web designer and faultless technician. She’s a pleasure to work with, great value for money and will always go the extra mile when necessary." img="images/dummy1.jpg" person="Jose Wheeler" occupation="Owner of Wheeler Caterers" />

               {/* ------------------- Services Section -----------------------------  */}
               <Services />

                {/* ------------------- Testimonial Section -----------------------------  */}

                <Testimonial testimonial="Deepika is not only great for design and development, she is a problem solver who always delivers an exceptional quality of work. Highly recommended." img="images/dummy2.jpg" person="Daryl Osborne" occupation="Owner of Wynquest"/>

           </div>
        )
    }
}
