import React, { Component } from 'react'
import './componentBanner.css'

export default class ComponentBanner extends Component {
    render() {
        return (
            <div className="container-fluid text-center p-0">
                <div className="banner d-flex justify-content-center" >
                    <div className='row m-0'>
                    <div className='display-4'>
                        <div className="text-center mb-3">
                           <h1 className="page-heading"> <b>{this.props.componentTitle}</b></h1>
                            <div className="col-8 text-center mx-auto"><h2>{this.props.description} </h2></div>
                            </div>
                            </div>
                    </div>  
                </div>
            </div>
        )
    }
}
