import React, { Component } from 'react'
import { FaGrinWink } from 'react-icons/fa'

import './testimonial.css'

export default class Testimonial extends Component {
    render() {
        return (
            <div className="testimonial text-center p-5">
                <div class="container quote">
                <h5 className="featured-test">TESTIMONIALS</h5>
                <div className="text-center justify-content-center d-flex "><div className="head-border-test"></div></div>
                    <div className="p-2">
                    <img src={this.props.img} className="rounded-circle" alt="Testimonial" width="200px" height="200px"/>
                    </div>
                        <p className="testimonial-text">
                        {this.props.testimonial} <FaGrinWink />
                        </p>
                    <h4><b>{this.props.person}</b></h4>
                    <h6 className="occupation">{this.props.occupation}</h6>
                
                </div>
            </div>
        )
    }
}
