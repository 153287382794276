import React, { Component } from 'react'

export default class ProjectImage extends Component {
    render() {
        return (
            
            <div className="banner-image col-sm-12 col-md-6">
            <img src={this.props.img} alt={this.props.img} className="img-fluid" />
        </div> 
        )
    }
}
