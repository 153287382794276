import React, { Component } from 'react'
import { FaPaintBrush, FaCode, FaPenNib } from 'react-icons/fa';
export default class ProjectDesc extends Component {
    render() {
       // var pr1 = "https://armoire.azurewebsites.net/";
        return (
            
                


                <div className='project-text col-sm-12 col-md-6'>
                        <h2 className="project-title">{this.props.title}</h2>
                        <p>{this.props.description}</p>
                        <ul className="project-features">
                        <li><FaPaintBrush />  {this.props.contribution1} </li>
                    <li><FaPenNib />  {this.props.contribution2} </li>
                    <li><FaCode />  {this.props.contribution3} </li>
                        </ul>
                            
                        <div className="col-12 p-0 text-left"><a href="https://armoire.azurewebsites.net/" className="btn-outline-secondary rounded-pill project-button project-button-test service-button py-3 px-4">View Details </a></div>
                    </div> 
                    
               




            )
    }
}
